.code-block{
  padding: 0;
  overflow: auto;
  pre[class*='language-']{
    padding: 1.6rem;
    border: 0;
    margin: 0;
    border-radius: 0;
  }
}


:not(pre) > code[class*="language-"], pre[class*="language-"]{
  background-color: map-get($grays, '100');
  border: 1px solid map-get($grays, '300');
}

// code.language-html:first-child{
//   display: block;
// }

// code[class*='language-'], pre[class*='language-'] {
//   color: $gray-300;
//   font-family: $font-family-monospace;
// }
// pre[class*="language-"]{
//   margin: 0;
// }