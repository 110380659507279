/*-----------------------------------------------
|   Documentation link
-----------------------------------------------*/

.contains-anchor{
  a{
    opacity: 0;
    transition-property: opacity;
  }
  @include hover-focus{
    a{
      opacity: 1;
      text-decoration: none;
    }
  }
}
.token.property, .token.tag, .token.constant, .token.symbol, .token.deleted{
  color: $danger;
}
.token.punctuation{
  color: $gray-400;
}
.components-nav{
  font-family: $font-family-sans-serif;
  font-size: 0.85rem;
}

/*-----------------------------------------------
|   Component examples
-----------------------------------------------*/
/*-----------------------------------------------
|   Borders
-----------------------------------------------*/
.component-example{
  font-family: $font-family-sans-serif;
  [class^='border']{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 8rem;
    margin: .75rem;
    background-color: $gray-100;
  }
}


/*-----------------------------------------------
|   Utilities
-----------------------------------------------*/
.border-component{
  [class^=border],
  [class^="rounded-"]{
    display: inline-block;
    width: 5rem;
    height: 5rem;
    margin: .25rem;
  }

  [class^=border] {
    background-color: var(--#{$variable-prefix}200);
  }

  [class^="rounded-"] {
    background-color: var(--#{$variable-prefix}200);
  }
}

#loaders{
  [class^="spinner"]{
    margin-right: 0.5rem;
  }
}
